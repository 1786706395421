import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const rootPath = (window.Router && window.Router.rootPath) ? window.Router.rootPath : "/"

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            name: 'root',
            path: `${rootPath}`
        },
        {
            name: 'detail',
            path: `${rootPath}detail/:appId`
        },
        {
            name: 'building',
            path: `${rootPath}building/:buildingId`
        }
    ]
})

export default router
